import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main-button-section section noselect" }
const _hoisted_2 = ["value"]

import { ref, watch } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'MainButton',
  props: {
    isDisabled: { type: Boolean },
    isVisible: { type: Boolean },
    passClickEventThroughDisabled: { type: Boolean, default: false },
    title: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

const props = __props;

const isDisabled = ref<boolean>(props.isDisabled);
const isVisible = ref<boolean>(props.isVisible);
const title = ref<string>(props.title);

watch(props, async (newProps, oldProps) => {
  isDisabled.value = newProps.isDisabled;
  isVisible.value = newProps.isVisible;
  title.value = newProps.title;
});

const emit = __emit;

const onMainButtonClicked = () => {
  if (props.passClickEventThroughDisabled || !props.isDisabled)
    emit('click');
};

return (_ctx: any,_cache: any) => {
  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: _normalizeClass(["main-button", isDisabled.value ? 'disabled' : '']),
      value: title.value,
      onClick: onMainButtonClicked
    }, _toDisplayString(title.value), 11, _hoisted_2)
  ], 512)), [
    [_vShow, isVisible.value]
  ])
}
}

})