<template>
  <div class="main-button-section section noselect" v-show="isVisible">
    <button class="main-button" v-bind:class="isDisabled ? 'disabled' : ''" :value="title"
      @click="onMainButtonClicked">{{ title }}</button>
  </div>
</template>

<style lang="css" scoped>
.main-button-section {
  position: sticky;
  bottom: 0.5rem;
  margin-bottom: 0rem;

}

.main-button {
  background-color: var(--app-theme-button-color);
  color: var(--app-theme-button-text-color);
  width: 100%;
  text-transform: uppercase;
  font-size: 1rem;
  font-weight: 400;
  border-radius: 0.5rem;
  text-align: center;
  padding: 0.75rem 0.25rem;
  border: 0.1rem solid var(--app-theme-button-color);
  transition: .3s;
}

.main-button.disabled {
  background-color: var(--app-theme-hint-color);
  border: 0.1rem solid var(--app-theme-hint-color);
}

@media(hover: hover) and (pointer: fine) {
  .main-button:hover {
    border-color: var(--app-theme-button-color);
    background-color: transparent;
    color: var(--app-theme-button-color);
  }

  .main-button.disabled:hover {
    border-color: var(--app-theme-hint-color);
    background-color: transparent;
    color: var(--app-theme-destructive-text-color);
  }
}

.main-button:active {
  border-color: var(--app-theme-button-color);
  background-color: transparent;
  color: var(--app-theme-button-color);
}

.main-button.disabled:active {
  border-color: var(--app-theme-hint-color);
  background-color: transparent;
  color: var(--app-theme-destructive-text-color);
}
</style>

<script setup lang="ts">
import { ref, defineModel, defineEmits, defineProps, watch, withDefaults } from 'vue';

const props = withDefaults(defineProps<{
  isDisabled: boolean,
  isVisible: boolean,
  passClickEventThroughDisabled: boolean,
  title: string
}>(), { passClickEventThroughDisabled: false });

const isDisabled = ref<boolean>(props.isDisabled);
const isVisible = ref<boolean>(props.isVisible);
const title = ref<string>(props.title);

watch(props, async (newProps, oldProps) => {
  isDisabled.value = newProps.isDisabled;
  isVisible.value = newProps.isVisible;
  title.value = newProps.title;
});

const emit = defineEmits<{ click: [] }>();

const onMainButtonClicked = () => {
  if (props.passClickEventThroughDisabled || !props.isDisabled)
    emit('click');
};
</script>