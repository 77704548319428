import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "main" }

import { onMounted, ref } from 'vue'

import ProductDataLoadingView from './page/ProductDataLoadingView.vue';
import { useSourceDataStore } from './store/SourceDataStore';

import { useWebAppBackButton, useWebApp, useWebAppPopup } from 'vue-tg';
import router from './router';


export default /*@__PURE__*/_defineComponent({
  __name: 'App',
  setup(__props) {

const { close, initDataUnsafe } = useWebApp();
const { onBackButtonClicked, showBackButton } = useWebAppBackButton();
const { showAlert } = useWebAppPopup();
const sourceDataStore = useSourceDataStore();
const isLoading = ref<boolean>(false);

onMounted(() => {
  showBackButton();
  console.log("\n⠀⠀⠀⠀⠀⠀⠀⠀⢀⣀⣤⣤⣤⣤⣤⣤⣀⡀⠀⠀⠀⠀⠀⠀⠀⠀\n⠀⠀⠀⠀⠀⣠⣴⣿⣿⠿⠛⠛⠛⠛⠛⠛⠿⣿⣿⣦⣄⠀⠀⠀⠀⠀\n⠀⠀⠀⣠⣾⡿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⢿⣷⣄⠀⠀⠀\n⠀⠀⣼⣿⠟⠁⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠀⠈⠻⣿⣧⠀⠀\n⠀⣼⣿⠏⠀⢠⣴⡿⠛⠛⢦⡀⠀⠀⢀⣴⡿⠛⠛⢦⡀⠀⠹⣿⣧⠀\n⢰⣿⡏⠀⠀⣾⣿⠀⠀⠀⣀⣷⣤⣤⣾⣿⠀⠀⠀⠀⣷⠀⠀⢹⣿⡆\n⣿⣿⠀⠀⠀⠹⣿⣷⣴⣿⡿⠟⠛⠛⠻⢿⣿⣦⣤⣾⠏⠀⠀⠀⣿⣿\n⣿⣿⠀⠀⠀⠀⢨⣿⡿⠋⢀⣠⣤⣤⣄⡀⠙⢿⣿⡅⠀⠀⠀⠀⣿⣿\n⢿⣿⣤⣾⡷⠀⣾⣿⠁⠰⣿⣿⣿⣿⣿⣿⠆⠈⣿⣷⠀⢾⣷⣤⣿⡿\n⠈⣿⣿⠉⠀⠀⣿⣿⠀⠀⣿⣿⠛⠛⣿⣿⠀⠀⣿⣿⠀⠀⠉⣿⣿⠁\n⠀⣿⣿⠀⠀⠀⢻⣿⡄⢠⣿⣿⣤⣤⣿⣿⡄⢠⣿⡟⠀⠀⠀⣿⣿⠀\n⠀⠙⠋⠀⠀⠀⠈⠻⣿⣿⠿⠛⠛⠛⠛⠿⣿⣿⠟⠁⠀⠀⠀⠙⠋⠀\n\n                  Jake the Dog");
  console.log("Your Telegram Init data: ", initDataUnsafe);
  
  const currentTimestamp: number = Math.floor(Date.now() / 1000);
  const expiration_timeout = process.env.VUE_APP_WEBAPP_INIT_DATA_EXPIRATION_TIMEOUT as number;
  console.log("Environment: ", process.env);
  console.log(`Telegram auth date: ${initDataUnsafe.auth_date}`);
  console.log(`Browser date: ${currentTimestamp}`);
  console.log(`Expiration date: ${expiration_timeout}`);
  if (currentTimestamp > initDataUnsafe.auth_date + expiration_timeout) {
    showAlert(
      "Время авторизации истекло, вам необходимо открыть приложение заново!\n\n"+
      "Алгоритм проверки так же зависит от системного времени вашего устройства. Проверьте, верно ли оно настроено.", 
      () => {
        close();
      }
    );
  }
  
});

onBackButtonClicked(() => {
  if (window.history.state.back === null) {
    close();
    return;
  }
  router.back();
});

onMounted(async () => {
  isLoading.value = true;
  await sourceDataStore.loadData();
  isLoading.value = false;
});


return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (!isLoading.value)
      ? (_openBlock(), _createBlock(_component_router_view, { key: 0 }))
      : (_openBlock(), _createBlock(ProductDataLoadingView, { key: 1 }))
  ]))
}
}

})