import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "category-select-view" }
const _hoisted_2 = ["onClick"]
const _hoisted_3 = { class: "subcategory-section" }
const _hoisted_4 = ["onClick"]

import { onMounted, ref} from 'vue';
import { useSourceDataStore } from '@/store/SourceDataStore';
import router from '@/router';
import MainButton from '@/components/MainButton.vue';
import { useOrderStore } from '@/store/OrderStore';


export default /*@__PURE__*/_defineComponent({
  __name: 'CategorySelect',
  setup(__props) {

const sourceDataStore = useSourceDataStore();
const orderStore = useOrderStore();
const categories = sourceDataStore.categories;

const mainButtonText = ref<string>((`Открыть корзину (${orderStore.orderItems.length})`));

// onMainButtonClicked(() => {
//   router.push({
//     name: "order-view"
//   });
// });

const categorySelected = (slug: string) => {
  router.push({
    name: "product-select",
    params: { categorySlug: slug },
  });
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_unref(categories), (category) => {
        return (_openBlock(), _createElementBlock("div", {
          class: "category-section section noselect",
          key: category.slug
        }, [
          _createElementVNode("button", {
            class: "category-button category-main",
            onClick: ($event: any) => (categorySelected(category.slug))
          }, _toDisplayString(category.title), 9, _hoisted_2),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(category.children, (subcategory) => {
              return (_openBlock(), _createElementBlock("button", {
                key: subcategory.slug,
                class: "category-button category-sub",
                onClick: ($event: any) => (categorySelected(subcategory.slug))
              }, _toDisplayString(subcategory.title), 9, _hoisted_4))
            }), 128))
          ])
        ]))
      }), 128))
    ]),
    _createVNode(MainButton, {
      title: `Открыть корзину (${_unref(orderStore).orderItems.length})`,
      "is-visible": true,
      "is-disabled": false,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_unref(router).push({ name: 'order-view' })))
    }, null, 8, ["title"])
  ], 64))
}
}

})