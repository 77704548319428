<template>
  <div class="category-select-view">
    <div class="category-section section noselect" v-for="category in categories" :key="category.slug">
      <button class="category-button category-main" @click="categorySelected(category.slug)">{{ category.title }}</button>
      <div class="subcategory-section">
        <button v-for="subcategory in category.children" :key="subcategory.slug" class="category-button category-sub" @click="categorySelected(subcategory.slug)">
          {{ subcategory.title }}
        </button>
      </div>
    </div>
  </div>
  <MainButton :title="`Открыть корзину (${orderStore.orderItems.length})`" :is-visible="true" :is-disabled="false" @click="router.push({ name: 'order-view' })"/>
</template>

<style scoped>
.category-button {
  border: 0.1rem solid var(--app-theme-button-color);
  border-radius: 0.5rem;
  padding: 0.25rem 1rem;
  transition: 0.3s;
  background-color: transparent;
}

.category-main {
  background-color: var(--app-theme-button-color) !important;
  color: var(--app-theme-button-text-color);
  font-size: 1.5rem;
}

@media(hover: hover) and (pointer: fine) {
  .category-main:hover {
    background-color: transparent !important;
    color: var(--app-theme-link-color);
  }
  .category-sub:hover {
    background-color: var(--app-theme-button-color);
    color: var(--app-theme-button-text-color);
  }
}

.category-main:active {
    background-color: transparent !important;
    color: var(--app-theme-link-color);
  }
  .category-sub:active {
    background-color: var(--app-theme-button-color);
    color: var(--app-theme-button-text-color);
  }

.category-sub {
  margin-top: 0.3rem;
  margin-right: 0.3rem;
}


.category-section {
  display: flex;
  flex-direction: column;
}

.subcategory-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>

<script lang="ts" setup>
import { onMounted, ref} from 'vue';
import { useSourceDataStore } from '@/store/SourceDataStore';
import router from '@/router';
import MainButton from '@/components/MainButton.vue';
import { useOrderStore } from '@/store/OrderStore';

const sourceDataStore = useSourceDataStore();
const orderStore = useOrderStore();
const categories = sourceDataStore.categories;

const mainButtonText = ref<string>((`Открыть корзину (${orderStore.orderItems.length})`));

// onMainButtonClicked(() => {
//   router.push({
//     name: "order-view"
//   });
// });

const categorySelected = (slug: string) => {
  router.push({
    name: "product-select",
    params: { categorySlug: slug },
  });
};
</script>